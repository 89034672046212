import React from 'react';
import * as Sentry from '@sentry/nextjs';
import Custom404 from "../pages/404"
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  

  static getDerivedStateFromError(error) {
    console.error('error 1', error.message);
    // Update state to trigger fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error to an error reporting service
    Sentry.captureException(error, { extra: errorInfo });
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can customize the fallback UI here
      return (
       <Custom404/>
      
      );
    }
  
    return this.props.children;
  }
}

export default ErrorBoundary;